<template>
	<section class="container">
		<div class="row mt-5">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.groupTemplate') }}</b>
				</h3>
			</div>
			<div class="col-auto ml-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="hasGroup">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<group-template v-model="supplier.groupTemplates" :disabled="disabled" @indexChanged="changeGroupIndex" @templateAdded="addTemplate" />
		<div class="row">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<template v-if="currentGroup">
				<div class="row">
					<insurance v-model="currentGroup.insurance" :disabled="disabled" :showAddInsurance="true" />
				</div>
			</template>

			<div class="row">
				<b-form-group id="signup-emailForm" class="col-3">
					<BaseActionButton class="w-100 btn-update" v-if="hasGroup" v-bind:clicked="clicked" v-bind:disabled="disabled && !clicked" type="submit">
						{{ $t('global.update') }}
					</BaseActionButton>
				</b-form-group>
			</div>
		</b-form>
	</section>
</template>
<script>
import GroupTemplate from '@/modules/general/supplier/components/GroupTemplate.vue'
import Insurance from '@/modules/global/components/Insurance.vue'

export default {
	name: 'Supplier',
	components: {
		'group-template': GroupTemplate,
		'insurance': Insurance,
	},
	props: ['value'],
	data() {
		return {
			currentGroupIndex: 0,
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
		if (!this.hasGroup) {
			this.setEditable()
		}
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		addTemplate(name) {
			this.supplier.addGroupTemplate(name)
		},
		changeGroupIndex(index) {
			console.log('Change group')
			this.currentGroupIndex = index
		},
		async onSubmit() {
			this.clicked = true
			var payload = this.supplier
			var id = this.id
			try {
				var result = await this.$store.dispatch('supplierVuex/update', {
					payload,
					id,
				})
				this.clicked = false
				this.toast('Success', 'Pensionselskabet er nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
	},
	computed: {
		hasGroup() {
			if (this.supplier?.groupTemplates) {
				return this.supplier?.groupTemplates?.length > 0 ?? false
			} else {
				return false
			}
		},
		currentGroup() {
			return this.supplier?.groupTemplates?.[this.currentGroupIndex] ?? null
		},
		id() {
			return this.$route.params.supplierId
		},
		supplier() {
			return this.$store.getters['supplierVuex/supplier']
		},
	},
}
</script>

<style lang="scss"></style>
